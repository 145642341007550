/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import KGFBike from "assets/images/products/KGFBike.jpeg";
import EVBicycle from "assets/images/products/ev-bicycle.jpeg";
// import EVTricycle from "assets/images/products/ev-tricycle.jpeg";
import Product1 from "assets/images/products/product-1.jpg";
import Product3 from "assets/images/products/product-3.jpg";
import Product4 from "assets/images/products/product-4.jpg";
import HorizontalInfoCard from "examples/Cards/InfoCards/HorizontalInfoCard";

// Images
import weeder from "assets/images/newImages/Weeder.png";
import retroFittedScooty from "assets/images/newImages/RetroFittedScooty.png";
import retroFittedScooty1 from "assets/images/newImages/RetroFittedScooty1.jpg";
import retroFittedScooty2 from "assets/images/newImages/RetrofittedScooty2.jpg";
import solarPoweredAgriculture1 from "assets/images/newImages/SolarPoweredAgriculture1.jpg";
import solarPoweredAgriculture2 from "assets/images/newImages/SolarPoweredAgriculture2.jpg";
import EBicycle1 from "assets/images/newImages/EBicycle1.jpg";
import EBicycle2 from "assets/images/newImages/EBicycle2.jpg";
import EBicycle3 from "assets/images/newImages/EBicycle3.png";
import EBicycle4 from "assets/images/newImages/EBicycle4.jpg";
import Servicing4 from "assets/images/newImages/Servicing4.png";
import Ebike1 from "assets/images/newImages/Ebike1.jpg";
import Tricycle1 from "assets/images/newImages/Tricycle1.jpg";
import Tricycle2 from "assets/images/newImages/Tricycle2.jpg";
import Tricycle3 from "assets/images/newImages/Tricycle3.jpg";
import RetrofittedStrectcher from "assets/images/newImages/RetrofittedStrectcher.jpg";
import eRickshaw from "assets/images/newImages/ERickshaw.jpg";
import eRickshaw1 from "assets/images/newImages/ERickshaw1.jpg";
import passengerCart from "assets/images/newImages/PassengerCart.jpg";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="public"
                    title="E-Bicycle"
                    description="Swap out your normal wheels for one with super power by retrofit"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="payments"
                    title="E-Tricycle"
                    description="Swap out your normal wheels for one with super power by retrofit"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="apps"
                    title="E-Bike/E-Scooty"
                    description="Swap out your normal wheels for one with super power by retrofit"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="3p"
                    title="Handicap E-Cycle"
                    description="Swap out your normal wheels for one with super power by retrofit"
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={KGFBike}
              // title=""
              // description=""
              action={null}
            />
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid container spacing={3} alignItems="center" sx={{ mt: { xs: 3, lg: 5 } }}>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={EVBicycle}
              // title="Get insights on Search"
              // description=""
              action={null}
            />
          </Grid>

          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={KGFBike}
              // title="Get insights on Search"
              // description=""
              action={null}
            />
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={Product1}
              // title="Get insights on Search"
              // description=""
              action={null}
            />
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid container spacing={3} alignItems="center" sx={{ mt: { xs: 3, lg: 5 } }}>
          <Grid item xs={12} lg={6} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={Product3}
              // title="Get insights on Search"
              // description=""
              action={null}
            />
          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={Product4}
              // title="Get insights on Search"
              // description=""
              action={null}
            />
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid container spacing={3} alignItems="center" sx={{ mt: { xs: 3, lg: 5 } }}>
          <Grid item xs={12}>
            <MKBox mb={1}>
              <HorizontalInfoCard
                imageArray={[weeder]}
                name="Motorized Weeder – Technical Specifications"
                position={{ color: "info", label: "Application: Agricultural Weeding" }}
                htmlDescription="<p><strong>Electrical Specifications:</strong></p>
<ul>
<li><strong>Motor Type:</strong> Axial PMDC Motor</li>
<li><strong>Operating Voltage:</strong> 24V DC</li>
<li><strong>Power Rating:</strong> 250W</li>
<li><strong>Drive Mechanism:</strong> High-efficiency gear arrangement for enhanced torque</li>
<li><strong>Battery Type:</strong> Lithium-ion (Li-ion)</li>
<li><strong>Battery Capacity:</strong> 15Ah(as per requirement)</li>
<li><strong>Operating Time:</strong> Extended runtime for efficient field operations</li>
<li><strong>Control System:</strong> Integrated motor controller for speed and torque optimization</li>
</ul>
<p><strong>Key Features:</strong></p>
<p>✅ <strong>Longer Operation Time</strong> &ndash; Powered by a 15Ah Li-ion battery for extended field use<br /> ✅ <strong>High Torque Output</strong> &ndash; Gear arrangement ensures smooth performance in varying soil conditions<br /> ✅ <strong>Eco-Friendly &amp; Cost-Effective</strong> &ndash; Reduces manual labor and eliminates the need for chemical herbicides<br /> ✅ <strong>User-Friendly Design</strong> &ndash; Lightweight and ergonomically designed for ease of use</p>
<p>This Electric Weeder is a compact and efficient agricultural tool designed for easy weed removal in farms and gardens. Powered by a 24V, 250W axial PMDC motor with a gear arrangement, it enhances torque for better soil penetration. The 15Ah Li-ion battery ensures long operational hours, making it ideal for sustainable farming.</p>"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12}>
            <MKBox mb={1}>
              <HorizontalInfoCard
                imageArray={[retroFittedScooty, retroFittedScooty1, retroFittedScooty2]}
                name="Retrofitted Electric Scooty – A New Life for Your Ride!"
                position={{
                  color: "info",
                  label:
                    "Upgrade your old Scooty Pep into a powerful, eco-friendly electric vehicle with our EV retrofitting solution.",
                }}
                htmlDescription="<p><strong>Retrofitted Electric Scooty &ndash; A New Life for Your Ride!</strong></p>
<p>Upgrade your old Scooty Pep into a powerful, eco-friendly electric vehicle with our EV retrofitting solution.</p>
<p>✅ <strong>Zero Emission, High Performance</strong><br /> ✅ <strong>Cost-Effective &amp; Sustainable</strong><br /> ✅ <strong>Long-Lasting Battery with Fast Charging</strong><br /> ✅ <strong>Smooth &amp; Silent Ride with Low Maintenance</strong></p>
<p>♻ <strong>Convert today and embrace the future of green mobility!</strong> 🌱🚀</p>
<p><strong>🚀</strong><strong> ELECTRIC SCOOTY RETROFITTING SOLUTION</strong></p>
<p>🔹 <strong>Product:</strong> Retrofitted Electric Scooty</p>
<p>🔹 <strong>Motor Type:</strong> High-efficiency Electric Motor<br /> 🔹 <strong>Operating Voltage:</strong> 48V / 60V DC (as per requirement)<br /> 🔹 <strong>Power Rating</strong><strong>:</strong> 1200W(as per requirement)<br /> 🔹 <strong>Battery Type:</strong> Lithium-ion / Lead Acid<br /> 🔹 <strong>Battery Capacity:</strong> 26Ah (as per requirement)<br /> 🔹 <strong>Charging Time:</strong> (as per requirement)<br /> 🔹 <strong>Top Speed:</strong> 55/60km/h<br /> 🔹 <strong>Range per Charge:</strong> 60 km<br /> </p>
<p>🌱 <strong>Eco-Friendly | Cost-Effective | Low Maintenance</strong><br /> <strong>Upgrade your ride today!</strong> ♻🔋⚡</p>
<p>The Solar-Powered <strong>E-Rickshaw </strong>is an eco-friendly, self-sustaining unit designed for small businesses, food stalls, and charging stations. Equipped with solar panels and battery storage, it operates independently, reducing electricity costs and ensuring uninterrupted service. Its portable and weather-resistant design makes it ideal for outdoor and remote locations.</p>"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12}>
            <MKBox mb={1}>
              <HorizontalInfoCard
                imageArray={[solarPoweredAgriculture1, solarPoweredAgriculture2]}
                name="Retrofitted Solar-Powered Agricultural Processing Unit"
                position={{
                  color: "info",
                  label: "Sustainable | Efficient | Cost-Effective",
                }}
                htmlDescription="<h4><strong>Product Overview:</strong></h4>
<p>Designed for efficient post-harvest processing, this <strong>Solar-Powered Agricultural Processing Unit</strong> enhances productivity while promoting sustainable farming. Ideal for threshing, winnowing, and grain processing, it operates on renewable energy, reducing operational costs and environmental impact.</p>
<h4><strong>Key Features:</strong></h4>
<p>✅ <strong>Solar-Powered Operation</strong> &ndash; Reduces electricity dependency, ideal for remote areas<br /> ✅ <strong>High-Efficiency Motor</strong> &ndash; Ensures smooth and reliable processing<br /> ✅ <strong>Robust &amp; Durable Design</strong> &ndash; Built for long-term use in tough conditions<br /> ✅ <strong>Easy to Operate &amp; Maintain</strong> &ndash; Minimal training required for farmers<br /> ✅ <strong>Cost-Effective Solution</strong> &ndash; Saves on fuel and electricity expenses</p>
<h4><strong>Technical Specifications:</strong></h4>
<p>🔹<strong>Power Source:</strong> Solar Panel System<br /> 🔹<strong>Motor Rating:</strong> High-efficiency electric motor<br /> 🔹 <strong>Processing Capacity:</strong> Customizable based on requirements<br /> 🔹 <strong>Material:</strong> Heavy-duty steel frame for durability<br /> 🔹 <strong>Usage:</strong> Suitable for threshing, winnowing, and grain processing panels and battery storage, it operates independently, reducing electricity costs and ensuring uninterrupted service. Its portable and weather-resistant design makes it ideal for outdoor and remote locations.</p>"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12}>
            <MKBox mb={1}>
              <HorizontalInfoCard
                imageArray={[Servicing4]}
                name="🚗 Electric Vehicle (EV) Retrofittings Solution"
                position={{
                  color: "info",
                  label: "",
                }}
                htmlDescription="<p>🔹 <strong>Product:</strong> Retrofitted Electric Car<br /> 🔹 <strong>Technology:</strong> Smart EV Conversion Kit<br /> 🔹 <strong>Battery:</strong> High-Efficiency Li-Ion Battery Pack<br /> 🔹 <strong>Motor:</strong> Powerful &amp; Energy-Efficient Electric Drive<br /> 🔹 <strong>Range:</strong> Extended Mileage with Optimized Power Usage<br /> 🔹 <strong>Charging:</strong> Fast Charging Compatible</p>
<p>🌍 <strong>Sustainable | Cost-Effective | Low Maintenance</strong><br /> <strong>Convert your fuel car into an eco-friendly EV today!</strong> ⚡♻</p>"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12}>
            <MKBox mb={1}>
              <HorizontalInfoCard
                imageArray={[EBicycle1, EBicycle2, EBicycle3, EBicycle4]}
                name="🚴‍♂️ EVART ELECTRIC BICYCLE ⚡"
                position={{
                  color: "info",
                  label: "🌍 Eco-Friendly | Cost-Effective | Smart Mobility",
                }}
                htmlDescription="<p>✅ <strong>Battery-Powered for Extended Range</strong><br /> ✅ <strong>Lightweight &amp; Durable Frame</strong><br /> ✅ <strong>Smooth Ride with Pedal Assist &amp; Throttle Mode</strong><br /> ✅ <strong>Perfect for Daily Commutes &amp; Recreational Rides</strong></p>
<p>Technical Specifications:<br /> 🔹 <strong>Battery Type:</strong> Lithium-ion <br /> 🔹 <strong>Range:</strong> Up to 60-70 km per charge with solar support<br /> 🔹 <strong>Motor Power:</strong> 250W Brushless Hub Motor</p>
<p>🔹 <strong>Top Speed:</strong> 25 km/h<br /> 🔹 <strong>Charging Time:</strong> 3-4 hours (plug-in) <br /> 🔹 <strong>Frame Material:</strong> Alloy Steel for durability<br /> 🔹 <strong>Speed Modes:</strong> Multiple levels of pedal assist</p>
<p>💰 <strong>Affordable &amp; Sustainable Alternative to Fuel-Powered Vehicles!</strong></p>"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12}>
            <MKBox mb={1}>
              <HorizontalInfoCard
                imageArray={[Ebike1]}
                name="🏍️ Retrofitted Electric Bike by EVART ⚡"
                position={{
                  color: "info",
                  label: "🌿 Sustainable | Cost-Effective | High Performance",
                }}
                htmlDescription="<p>EVART Engineering &amp; Automobiles LLP transforms conventional bikes into efficient electric motorcycles, promoting eco-friendly mobility. Our retrofitted bikes feature advanced batteries, powerful motors, and optimized energy systems, ensuring better performance, lower costs, and zero emissions. Showcased in key industry events like Energy Swaraj Yatra, our solutions support sustainable transportation. Experience the benefits of electric mobility with our cutting-edge retrofitting technology. Contact us today to convert your bike and drive towards a greener future!</p>
<p>✅ <strong>Convert Your Existing Bike into an EV</strong><br /> ✅ <strong>Zero Emissions, Maximum Efficiency</strong><br /> ✅ <strong>High-Torque Motor for Smooth Acceleration</strong><br /> ✅ <strong>Long-Lasting Battery with Fast Charging</strong></p>
<p>&nbsp;</p>
<p><strong>Technical Specifications:</strong></p>
<p><br /> 🔹 <strong>Battery Type:</strong> Lithium-ion <br /> 🔹 <strong>Range:</strong> Up to 55-60 km per charge with solar support<br /> 🔹 <strong>Motor Power:</strong> 1500W Brushless Hub Motor</p>
<p>🔹 <strong>Top Speed:</strong> 55-60 km/h<br /> 🔹 <strong>Charging Time:</strong> 3-4 hours (plug-in) <br /> 🔹 <strong>Frame Material:</strong> Alloy Steel for durability</p>
<p>💰 <strong>Affordable Conversion Solutions &ndash; Upgrade to Electric Today!</strong></p>"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12}>
            <MKBox mb={1}>
              <HorizontalInfoCard
                imageArray={[Tricycle1, Tricycle2, Tricycle3]}
                name="Electric Handicapped Tricycle – Sustainable Mobility Solution"
                position={{
                  color: "info",
                  label: "",
                }}
                htmlDescription="<p>The <strong>Electric Handicapped Tricycle</strong> by EVART Engineering is designed to empower individuals with mobility challenges, offering a safe, comfortable, and efficient mode of transportation. This tricycle features a <strong>sturdy frame, ergonomic seating, and an electric motor assist</strong>, allowing effortless travel on urban and rural roads. Equipped with <strong>rechargeable lithium-ion batteries</strong>, it ensures extended range and reliability. The <strong>easy-to-use handlebar controls</strong> and <strong>adjustable speed settings</strong> provide a user-friendly experience, while its <strong>low-step entry design</strong> ensures accessibility.With <strong>anti-slip wheels and a stable three-wheel configuration</strong>, safety and stability are prioritized. The tricycle supports <strong>customized seating and additional accessories</strong> to suit individual needs. Ideal for daily commutes, personal independence, and rehabilitation purposes, this vehicle enhances mobility and quality of life.</p>
<p><strong>Specifications:</strong></p>
<p>✅ <strong>Solar Panel:</strong> High-efficiency 150W/200W solar panel<br /> ✅ <strong>Battery Type:</strong> Lithium-ion / Lead-acid (customizable)<br /> ✅ <strong>Motor Power:</strong> 250W &ndash; 500W BLDC hub motor<br /> ✅ <strong>Range:</strong> 30-40 km per charge (solar-assisted)<br /> ✅ <strong>Charging Time:</strong> 4-6 hours (electric) + solar charging support<br /> ✅ <strong>Payload Capacity:</strong> Up to 250 kg<br /> ✅ <strong>Frame Material:</strong> Lightweight yet durable steel/aluminum</p>
<p><strong>Choose sustainability with EVART &ndash; Drive the future, today!</strong></p>"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12}>
            <MKBox mb={1}>
              <HorizontalInfoCard
                imageArray={[RetrofittedStrectcher]}
                name="Retrofitted Electric Stretcher "
                position={{
                  color: "info",
                  label: "",
                }}
                htmlDescription="<p>The <strong>Retrofitted Electric Stretcher</strong> is designed to enhance patient transportation with <strong>motorized mobility and ergonomic controls</strong>. Equipped with a<strong> 250W&ndash;500W motor</strong> and <strong>rechargeable lithium-ion battery</strong>, it ensures <strong>smooth and effortless movement</strong> in hospitals and emergency settings. The adjustable height and reclining mechanism provide <strong>optimal patient comfort</strong>, while its <strong>durable frame</strong> supports up to <strong>150 kg</strong>. Ideal for modern healthcare facilities, this stretcher <strong>reduces manual effort</strong><strong> and enhances patient care.</strong></p>
<p><strong>Specifications:</strong></p>
<ul>
<li><strong>Frame Material:</strong> High-strength steel with corrosion resistance</li>
<li><strong>Motorized Adjustment:</strong> Height, backrest, and leg elevation control</li>
<li><strong>Power Supply:</strong> Rechargeable battery &amp; AC power support</li>
<li><strong>Mobility:</strong> Lockable caster wheels for easy movement</li>
<li><strong>Load Capacity:</strong> Up to 250 kg</li>
<li><strong>Control System:</strong> Remote-operated for seamless adjustments</li>
<li><strong>Safety Features:</strong> Emergency manual override and side railings</li>
</ul>
<p><strong>Trust EVART for next-gen medical mobility solutions!</strong></p>"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12}>
            <MKBox mb={1}>
              <HorizontalInfoCard
                imageArray={[eRickshaw, eRickshaw1]}
                name="Retrofitted Solar-Powered E-Rickshaw: Technical Specifications & Features"
                position={{
                  color: "info",
                  label:
                    "Application: Ideal for small businesses, food stalls, charging stations, and outdoor retail",
                }}
                htmlDescription="<h3><strong>Technical Specifications:</strong></h3>
<p>🔹 <strong>Solar Panel Capacity:</strong> 550W<br /> 🔹 <strong>Battery Type:</strong> Lithium-ion / Lead Acid<br /> 🔹 <strong>Battery Capacity:</strong> 120 Ah<br /> 🔹 <strong>Mobility:</strong> Mounted on wheels for easy relocation</p>
<p>🔹 <strong>Motor Type:</strong> High-efficiency Electric Motor<br /> 🔹 <strong>Operating Voltage:</strong> 48V / 60V DC (as per requirement)<br /> 🔹 <strong>Power Rating</strong><strong>:</strong> 1200W(as per requirement)<br /> 🔹 <strong>Battery Type:</strong> Lithium-ion / Lead Acid<br /> 🔹 <strong>Battery Capacity:</strong> 100Ah (as per requirement)<br /> 🔹 <strong>Seating Capacity:</strong> 4-6 passengers<br /> 🔹 <strong>Charging Time:</strong> (as per requirement)<br /> 🔹 <strong>Top Speed:</strong> 25km/h<br /> 🔹 <strong>Range per Charge:</strong> 80 km</p>
<p>🔹 <strong>Structure Material:</strong> Heavy-duty metal with weather-resistant coating</p>
<h3><strong>Key Features:</strong></h3>
<p>✅ <strong>100% Solar-Powered</strong> &ndash; Reduces dependency on grid electricity<br /> ✅ <strong>Mobile &amp; Self-Sufficient</strong> &ndash; Designed for remote and urban use<br /> ✅ <strong>Sustainable &amp; Cost-Effective</strong> &ndash; Saves energy costs with renewable power<br /> ✅ <strong>Customizable Design</strong> &ndash; Available in different sizes and power capacities<br /> ✅ <strong>Weatherproof &amp; Durable</strong> &ndash; Built for long-term outdoor operation</p>
<p>The Solar-Powered <strong>E-Rickshaw </strong>is an eco-friendly, self-sustaining unit designed for small businesses, food stalls, and charging stations. Equipped with solar panels and battery storage, it operates independently, reducing electricity costs and ensuring uninterrupted service. Its portable and weather-resistant design makes it ideal for outdoor and remote locations.</p>"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12}>
            <MKBox mb={1}>
              <HorizontalInfoCard
                imageArray={[passengerCart]}
                name="Retrofitted Electric Passenger Cart – Technical Specifications & Features"
                position={{
                  color: "info",
                  label:
                    "Application: Eco-friendly transport for campuses, resorts, industries, and public areas",
                }}
                htmlDescription="<h3><strong>Technical Specifications:</strong></h3>
<p>🔹 <strong>Motor Type:</strong> High-efficiency Electric Motor<br /> 🔹 <strong>Operating Voltage:</strong> 48V / 60V DC (as per requirement)<br /> 🔹 <strong>Power Rating<strong>:</strong></strong> 1000W(as per requirement)<br /> 🔹 <strong>Battery Type:</strong> Lithium-ion / Lead Acid<br /> 🔹 <strong>Battery Capacity:</strong> 100Ah (as per requirement)<br /> 🔹 <strong>Seating Capacity:</strong> 4-6 passengers<br /> 🔹 <strong>Charging Time:</strong> (as per requirement)<br /> 🔹 <strong>Top Speed:</strong> 25km/h<br /> 🔹 <strong>Range per Charge:</strong> 100 km<br /> 🔹 <strong>Frame Material:</strong> Sturdy metal chassis with a durable body</p>
<h3><strong>Key Features:</strong></h3>
<p>✅ <strong>Eco-Friendly &amp; Cost-Effective</strong> &ndash; Zero emissions, reducing carbon footprint<br /> ✅ <strong>Comfortable Seating</strong> &ndash; Spacious, cushioned seats with ergonomic design<br /> ✅ <strong>Smooth Ride</strong> &ndash; High-torque motor with efficient suspension for better stability<br /> ✅ <strong>Customizable Options</strong> &ndash; Available in different seating capacities and battery configurations<br /> ✅ <strong>Low Maintenance</strong> &ndash; Durable components for long-term reliability</p>
<p>This Electric Passenger Cart is an eco-friendly, battery-operated vehicle designed for smooth and efficient transportation in campuses, resorts, industries, and public spaces. With comfortable seating, a durable frame, and a high-torque motor, it ensures a quiet, cost-effective, and sustainable ride.</p>"
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
