/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import HorizontalInfoCard from "examples/Cards/InfoCards/HorizontalInfoCard";

import training3 from "assets/images/training/training-3.jpg";
import training4 from "assets/images/training/training-4.jpg";
import training5 from "assets/images/training/training-5.jpg";
// import training1 from "assets/images/training/training-1.jpg";
import training6 from "assets/images/training/training-6.jpg";
import training7 from "assets/images/training/training-7.jpg";

// Images
import Trainings1 from "assets/images/newImages/Trainings1.jpg";
import Trainings2 from "assets/images/newImages/Trainings2.jpg";
// import Trainings3 from "assets/images/newImages/Trainings3.jpg";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={4}>
            <CenteredBlogCard
              image={training3}
              // title="Get insights on Search"
              // description="Website visitors today demand a frictionless user expericence — especially when using search. Because of the hight standards."
              action={null}
            />
          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="public"
                    title="Long Term Training"
                    description="A 6 months intensive online and offline training classes with hands on practice by building/converting electric vehicle."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="payments"
                    title="Short Term Training"
                    description="One month online and offline training classes on components and wire harnessing of electric vehicles ."
                  />
                </MKBox>
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="apps"
                    title="Controller servicing"
                    description="All types of Controller(E-Rickshaw, E-Scooter, E-Cycle, etc) are being repaired."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="3p"
                    title="A-Z Solutions of Electric Vehicle"
                    description="All types of services for Electric Vehicle"
                  />
                </MKBox>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid container spacing={3} alignItems="center" sx={{ mt: { xs: 3, lg: 5 } }}>
          <Grid item xs={12}>
            <MKBox mb={1}>
              <HorizontalInfoCard
                imageArray={[Trainings1, Trainings2]}
                name="Trainings"
                position={{
                  color: "info",
                  label: "",
                }}
                htmlDescription="<p>Empowering professionals through interactive training and hands-on practice. Our sessions provide practical insights, real-world applications, and in-depth learning experiences to enhance skills and knowledge. Join us for industry-focused training designed for excellence.</p>"
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid container spacing={3} alignItems="center" sx={{ mt: { xs: 3, lg: 5 } }}>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={training4}
              // title="Get insights on Search"
              // description=""
              action={null}
            />
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={training5}
              // title="Get insights on Search"
              // description=""
              action={null}
            />
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={training6}
              // title="Get insights on Search"
              // description=""
              action={null}
            />
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid container spacing={3} alignItems="center" sx={{ mt: { xs: 3, lg: 5 } }}>
          <Grid item xs={12} lg={12} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={training7}
              // title="Get insights on Search"
              // description=""
              action={null}
            />
          </Grid>
          {/* <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={service8}
              // title="Get insights on Search"
              // description=""
              action={null}
            />
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={service7}
              // title="Get insights on Search"
              // description=""
              action={null}
            />
          </Grid> */}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
